/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SupportIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || (props.height && Math.floor(+props.height * 0.9984166666666666)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 0.9984166666666666)) || 24}
		viewBox="0 0 24 24"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M23.961 13.417c0-.493-.075-2.352-.134-2.884A11.7 11.7 0 0012.049 0 11.924 11.924 0 00.115 11.467c-.007.143-.06 1.155-.064 1.758v.335a1.977 1.977 0 00-.049.429v5.024a1.992 1.992 0 001.99 1.99h2.02a1.992 1.992 0 001.99-1.99v-5.024a1.992 1.992 0 00-1.99-1.99H1.989a2 2 0 00-.4.041l.024-.493A10.443 10.443 0 0112.049 1.498a10.218 10.218 0 0110.289 9.2c.025.222.057.788.083 1.356a1.977 1.977 0 00-.449-.056h-2a1.992 1.992 0 00-1.99 1.99v5a1.983 1.983 0 001.06 1.748l-4.81 1.888v-.122a.749.749 0 00-.749-.749h-2.247a.749.749 0 00-.749.749v.749a.749.749 0 00.749.749H13.501l1.341-.007 7.714-3.028a.744.744 0 00.257-.179 1.987 1.987 0 001.147-1.795v-5a1.961 1.961 0 00-.036-.357.763.763 0 00.036-.216zm-21.971.081h2.02a.492.492 0 01.492.492v5.024a.492.492 0 01-.492.492H1.989a.492.492 0 01-.492-.492v-5.025a.492.492 0 01.492-.492zm17.493.492a.492.492 0 01.492-.492h2a.492.492 0 01.492.492v5a.492.492 0 01-.492.492h-2a.492.492 0 01-.492-.492z" />
	</svg>
))
SupportIconSvg.displayName = 'SupportIconSvg'

const SupportIcon = forwardRef((props, ref) => <Icon component={SupportIconSvg} ref={ref} {...props} />)
SupportIcon.displayName = 'SupportIcon'

SupportIcon.defaultProps = {
	...Icon.defaultProps,
}
SupportIcon.propTypes = {
	...Icon.propTypes,
}

export default SupportIcon
export { SupportIconSvg }
