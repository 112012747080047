import { useState, useEffect, useCallback } from 'react'
import {
	ButtonStyled,
	Collapse,
	CollapseAdditionalContent,
	Container,
	Stack,
	TypographyStyled,
} from './notify-message.styled'
import ArrowheadDownIcon from '../../atoms/icons/arrowhead-down-icon'
import XIcon from '../../atoms/icons/x-icon'
import { Collapse as MuiCollapse } from '@mui/material'
import { bool, elementType, node, object, oneOf, oneOfType, string } from 'prop-types'
import router from 'next/router'

const NotifyMessage = ({
	additionalContent,
	collapseAdditionalIcon,
	collapsed,
	collapseIcon,
	look,
	message,
	closeEnabled,
	...props
}) => {
	const [collapsedValue, setCollapsedValue] = useState(collapsed)
	const [collapsedAdditionalValue, setCollapsedAdditionalValue] = useState(collapsed)

	const onRouteChangeStart = useCallback(() => {
		setCollapsedAdditionalValue(false)
	}, [setCollapsedAdditionalValue])

	useEffect(() => {
		router.events.on('routeChangeStart', onRouteChangeStart)
		return () => {
			router.events.off('routeChangeStart', onRouteChangeStart)
		}
	}, [onRouteChangeStart])

	return message ? (
		<Collapse in={!collapsedValue} {...props}>
			<Stack direction="row">
				{closeEnabled && (
					<ButtonStyled
						Icon={collapseIcon}
						look={look}
						onClick={() => {
							setCollapsedValue(!collapsedValue)
						}}
						variant="icon"
					/>
				)}

				{additionalContent && (
					<ButtonStyled
						flipped={collapsedAdditionalValue}
						Icon={collapseAdditionalIcon}
						look={look}
						onClick={() => {
							setCollapsedAdditionalValue(!collapsedAdditionalValue)
						}}
						variant="icon"
					/>
				)}
			</Stack>

			<TypographyStyled additionalContent={additionalContent} align="center" look={look} component="div">
				{message}
			</TypographyStyled>

			{additionalContent && (
				<CollapseAdditionalContent in={collapsedAdditionalValue}>
					<Container>{additionalContent}</Container>
				</CollapseAdditionalContent>
			)}
		</Collapse>
	) : null
}

NotifyMessage.defaultProps = {
	collapsed: false,
	collapseAdditionalIcon: ArrowheadDownIcon,
	collapseIcon: XIcon,
	look: 'light',
	closeEnabled: false,
}

NotifyMessage.propTypes = {
	...MuiCollapse.propTypes,
	/**
	 * Hidden content
	 */
	additionalContent: node,
	/**
	 * Collapsed or not
	 */
	collapsed: bool,
	/**
	 * Collapse additional content icon
	 */
	collapseAdditionalIcon: elementType,
	/**
	 * Collapse icon
	 */
	collapseIcon: elementType,
	/**
	 * Dark or light
	 */
	look: oneOf(['dark', 'light']),
	/**
	 * Initial text
	 */
	message: oneOfType([object, string]),
	/**
	 * Closable or not
	 */
	closeEnabled: bool,
}

export default NotifyMessage
